<template lang="pug">
div

  v-layout(row no-gutters wrap class="pl-5 pr-5 mt-4 d-flex justify-end")

    v-menu(
      ref="menucriacao"
      v-model="criacaoDateMenu"
      :close-on-content-click="false"
      :return-value.sync="criacaoDates"
      transition="scale-transition"
      offset-y
      min-width="auto"
    )
      template(v-slot:activator="{ on, attrs }")
        div(
          class="mr-2"
          style="width: 210px" 
          v-bind="attrs"
          v-on="on"
        ) 
          v-text-field(
            width="200px"
            v-model="criacaoDatesText"
            label="Período de inclusão"
            readonly
            hide-details
            outlined 
            dense
            v-bind="attrs"
            v-on="on"
          )
      v-date-picker(
        v-model="criacaoDates"
        locale="pt-br"
        no-title
        scrollable
        range
      )
        v-spacer
        v-btn(
          class="mt-0"
          text
          color="primary"
          @click="criacaoDates = []; $refs.menucriacao.save(criacaoDates)"
        ) Limpar

        v-btn(
          class="mt-0"
          text
          color="primary"
          @click="criacaoDateMenu = false"
        ) Cancelar
        
        v-btn(
          class="mt-0"
          text
          color="primary"
          @click="$refs.menucriacao.save(criacaoDates)"
        ) OK

    v-menu(
      ref="menuenvio"
      v-model="envioDateMenu"
      :close-on-content-click="false"
      :return-value.sync="envioDates"
      transition="scale-transition"
      offset-y
      min-width="auto"
    )
      template(v-slot:activator="{ on, attrs }")
        div(
          style="width: 210px" 
          v-bind="attrs"
          v-on="on"
        )         
          v-text-field(
            v-model="envioDatesText"
            label="Período de envio"
            
            readonly
            hide-details
            outlined 
            dense
            v-bind="attrs"
            v-on="on"
          )
      v-date-picker(
        v-model="envioDates"
        locale="pt-br"
        no-title
        scrollable
        range
      )
        v-spacer
        v-btn(
          class="mt-0"
          text
          color="primary"
          @click="envioDates = []; $refs.menuenvio.save(envioDates)"
        ) Limpar

        v-btn(
          class="mt-0"
          text
          color="primary"
          @click="envioDateMenu = false"
        ) Cancelar
        
        v-btn(
          class="mt-0"
          text
          color="primary"
          @click="$refs.menuenvio.save(envioDates)"
        ) OK

    v-divider(vertical class="ml-3 mr-2")

    v-btn(
      color="primary"
      class="ml-2 mr-2 mt-0"
      fab
      small
      @click="getData()"
    )
      v-icon mdi mdi-refresh

    v-btn(
      color="primary"
      class="ml-2 mr-2 mt-0"
      fab
      small
      to="/agronotificacao/cadastro/0"
    )
      v-icon mdi mdi-plus

  v-container(fluid)
    DataFilter(
      :filterProps="filters"
      :items="datasetFiltered"
      :paginate="false"
    )

      template( v-slot:default="{ items }" )
        v-data-table(
          dense
          :footer-props="{itemsPerPageOptions: [15, 30, 50, 100], showCurrentPage: true, showFirstLastPage: true, itemsPerPageText: 'Itens por página'}"
          item-key="id"
          :headers="dataHeaders"
          :items="items"
          :single-expand="true"
          show-expand
        )

          template( v-slot:item.usuarios="{ item }" )
            span {{ item.usuarios.length }}
            
          template( v-slot:item.exigeconfirmacao="{ item }" )
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" 
                  :color="item.exigeconfirmacao ? 'blue' : 'grey lighten-2'"
                ) {{ item.exigeconfirmacao && item.tipoconfirmacao == 'TODOS' ? 'mdi mdi-check-decagram' : 'mdi-check-decagram-outline'}}
              span {{ item.exigeconfirmacao == false ? 'Sem confirmação' : item.tipoconfirmacao == 'TODOS' ? 'Confirmação de todos os usuários' : 'Ao menos uma confirmação de usuário'}}
            
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" class="ml-2" 
                  :color="item.bloqueiasistema ? 'blue' : 'grey lighten-2'"
                ) mdi-lock
              span {{ item.bloqueiasistema? 'Notificação com bloqueio' : 'Notificação sem bloqueio' }}

          template( v-slot:item.datahoracriado="{ item }" )
            span {{ new Date(item.datahoracriado).toLocaleString() }}

          template( v-slot:item.datahoraenvio="{ item }" )
            span(v-if="item.datahoraenvio") {{ new Date(item.datahoraenvio).toLocaleString() }}
            span(v-else) Não enviado

          template( v-slot:item.actions="{ item, expand, isExpanded }" )
            td(class="text-center pr-2 pl-0")
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    color="primary"
                    fab
                    small
                    icon
                    :to="'/agronotificacao/visualizacao/' + item.id"
                  )
                    v-icon mdi mdi-eye-circle-outline
                span Visualizar
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    color="primary"
                    fab
                    small
                    :disabled="item.status == 'ENVIADO'"
                    icon
                    :to="'/agronotificacao/cadastro/' + item.id"	
                  )
                    v-icon mdi mdi-circle-edit-outline
                span Editar

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    color="primary"
                    fab
                    small
                    :disabled="item.status == 'ENVIADO'"
                    icon
                    @click="itemInteracao = item; dialogExcluir = true"
                  )
                    v-icon mdi mdi-close-circle-outline
                span Excluir

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    color="primary"
                    fab
                    small
                    icon
                    @click="activeDialogClonar(item)"
                  )
                    v-icon mdi mdi-text-box-multiple-outline
                span Clonar mensagem

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    color="primary"
                    fab
                    small
                    :disabled="item.status == 'ENVIADO'"
                    icon
                    @click="itemInteracao = item; dialogEnviar = true;"
                  )
                    v-icon mdi mdi-send-circle-outline
                span Enviar
          
          template( v-slot:item.data-table-expand="{ item, isExpanded, expand }" )
            td(class="text-center pr-0 pl-0")
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    color="primary"
                    fab
                    small
                    :disabled="item.status == 'PENDENTE'"
                    @click="getUsuarios(item.id); expand(!isExpanded)"
                    icon
                  )
                    v-icon mdi mdi-information-outline
                span Informações de Envio e Confirmação

          template(v-slot:expanded-item="{ headers, item, expand, isExpanded }")
            td(colspan="123")
              v-layout(row wrap no-gutters class="pl-5 pr-0 mt-0 mb-3 d-flex justify-end")
                div(style="font-size: 16px; width: 350px;" class="mt-6 ml-2 mr-0") 
                  | Usuário cadastro: {{ item.usuariocadastro !== null ? item.usuariocadastro.split(':')[1] : ''}}
                div(style="font-size: 16px; width: 350px" class="mt-6 ml-2 mr-0") 
                  | Usuário envio: {{ item.usuarioenvio !== null ? item.usuarioenvio.split(':')[1] : ''  }}
                div(style="width: 200px" class="mt-4 ml-2 mr-0")
                  v-select(
                    v-model="filtrarLidas"
                    :items="['TODAS', 'LIDAS', 'LIDAS + CONFIRMADAS']"
                    label="Status"
                    outlined
                    hide-details
                    no-data-text="Nenhum item encontrado"
                    @change="filtrarEmpresasUsuarios()"
                    dense
                  )
              v-card(elevation="1" class="ma-0 mt-2 mb-2 pa-0")
                v-card-text
                  v-data-table(
                    dense
                    disable-pagination
                    hide-default-footer
                    item-key="idcliente"
                    :headers="dataHeadersUsuariosGrouped"
                    :items="datasetUsuariosLoaded ? datasetUsuariosGrouped : []"
                    :loading="!datasetUsuariosLoaded"
                    loading-text="Carregando usuários..."
                    no-data-text="Nenhum dado encontrado"
                    show-expand
                    :expanded.sync="item.isExpanded"	
                    :single-expand="true"
                  )

                    template(v-slot:expanded-item="{ item, isExpanded }")
                      td(colspan="123")
                        v-data-table(
                          class="ma-0 mt-4 mb-2 pa-0"
                          dense
                          :footer-props="{itemsPerPageOptions: [15, 30, 50, 100], showCurrentPage: true, showFirstLastPage: true, itemsPerPageText: 'Itens por página'}"
                          :sort-by="['codigoempresa', 'nomeusuario']"
                          :sort-desc="[false, false]"
                          :headers="dataHeadersUsuarios"
                          :items="datasetUsuariosLoaded ? item.items : []"
                          :expanded.sync="item.isExpanded"
                        )

                          template(v-slot:item.datahoraleitura="{ item }" )
                            | {{ item.datahoraleitura }}
                            v-tooltip(bottom v-if="item.computadorleitura")
                              template(v-slot:activator="{ on }")
                                v-icon(small v-on="on" class="ml-2 mr-2" color="primary") mdi mdi-desktop-classic
                              span {{ item.computadorleitura.split('|').join(' <|> ') }}

                          template(v-slot:item.datahoraconfirmacao="{ item }" )
                            | {{ item.datahoraconfirmacao }} 
                            v-tooltip(bottom v-if="item.computadorconfirmacao")
                              template(v-slot:activator="{ on }")
                                v-icon(small v-on="on" class="ml-2 mr-2" color="primary") mdi mdi-desktop-classic
                              span {{ item.computadorconfirmacao.split('|').join(' <|> ') }}

          
  v-dialog(v-model="dialogEnviar" max-width="600px")
    v-card
      v-card-title Encaminhar mensagem
      v-card-text(class="mt-2 mb-4 mr-2")
          div(style="font-size: 16px") Deseja enviar a mensagem? 
          p
          div(style="font-size: 16px") Ao confirmar, a mensagem será enviada para todos os destinatários sem possibilidade de cancelamento posterior
      v-card-actions(class="pb-4 pr-0")
        v-spacer
        v-btn(
          color="error"
          outlined
          @click="dialogEnviar = false"
        )
          v-icon(left) mdi mdi-close
          | Cancelar
        v-btn(
          elevation="2"
          color="success"
          @click="enviarNotificacao()"
        )
          v-icon(left) mdi mdi-check
          | Confirmar

  v-dialog(v-model="dialogExcluir" max-width="600px")
    v-card
      v-card-title 
        h3 Excluir Mensagem
      v-card-text
        h5(class="ma-0 pa-0") Deseja realmente excluir a mensagem?
      v-card-actions(class="mr-0")
        v-spacer
        v-btn(
          outlined
          color="primary"
          @click="dialogExcluir = false"
        )
          span Cancelar
        v-btn(
          outlined
          color="error"
          @click="deleteNotificacao()"
        )
          span Excluir

  v-dialog(
    v-model="exibirDialogClonar",
    v-if="exibirDialogClonar",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Clonar Mensagem
      v-card-text
        h5(class="ma-0 pa-0 pt-2") Tem certeza que deseja clonar a mensagem? 
        h5(class="ma-0 pa-0 pb-2") Após clonar, você será redirecionado para a tela de edição.
      v-card-actions(class="mr-0")
        v-spacer
        v-btn(
          color="primary" 
          outlined 
          @click="closeDialogClonar"
        )
          span Cancelar
        v-btn(
          color="success" 
          outlined 
          @click="clonarMensagem")
          span Clonar

</template>

<script>
import csapi from '@/api/csapi';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      exibirDialogClonar: false,
      filters: { includeSearch: true, items: [] },
      dataset: [],
      datasetFiltered: [],
      filtrarMarca: 'Ambos',
      filtrarLidas: 'TODAS',
      dialogEnviar: false,
      dialogExcluir: false,
      itemInteracao: {},
      criacaoDates: [new Date(), new Date()],
      criacaoDateMenu: false,
      envioDates: [],
      envioDateMenu: false,
      dataHeaders: [
        { text: 'ID', value: 'id', width: '70', sortable: true },
        { text: 'Título', value: 'titulo', sortable: true },
        { text: 'Softhouse', value: 'marca', width: '165', sortable: true },
        { text: 'Tipo', value: 'tipo', align: 'start', width: '120', sortable: true },
        { text: 'Incluído em', value: 'datahoracriado', width: '165', sortable: true },
        { text: 'Nível de usuário', value: 'nivelusuario', width: '100', align: 'start', sortable: true },
        { text: 'Com confirmação?', value: 'exigeconfirmacao', width: '100', align: 'center', sortable: true },
        { text: 'Enviado em', value: 'datahoraenvio', width: '165', sortable: true },
        { text: '', value: 'data-table-expand', width: '50', align: 'center', sortable: false },
        { text: 'Ações', value: 'actions', align: 'center', width: '240', sortable: false }
      ],
      dataHeadersUsuariosGrouped: [
        { text: 'ID', value: 'idcliente', width: '150', sortable: true },
        { text: 'Cliente', value: 'nomecliente', sortable: true },
        { text: 'Hash', value: 'hashcliente', sortable: true },
        { text: 'Enviadas', value: 'quantidade', width: '100', align: 'center', sortable: false },
        { text: 'Recebidas', value: 'recebidas', width: '120', align: 'center', sortable: false },
        { text: 'Lidas', value: 'lidas', width: '90', align: 'center', sortable: false },
        { text: 'Confirmadas', value: 'confirmadas', width: '120', align: 'center', sortable: false },
        { text: '', value: 'data-table-expand', width: '40', align: 'center', sortable: false },
      ],
      dataHeadersUsuarios: [
        { text: 'Empresa', value: 'codigoempresa', width: '80', sortable: true },
        { text: 'Nome usuário', value: 'nomeusuario', width: '150',  sortable: true },
        { text: 'Recebimento', value: 'datahorarecebimento', width: '100', align: 'center', sortable: false },
        { text: 'Leitura', value: 'datahoraleitura', width: '100', align: 'center', sortable: false },
        { text: 'Confirmação', value: 'datahoraconfirmacao', width: '100', align: 'center', sortable: false },
      ],
      datasetUsuariosGrouped: [],
      datasetUsuariosGroupedNotFiltered: [],
      datasetUsuariosLoaded: false,
    };
  },
  computed: {
    criacaoDatesText () {
      const date1 = this.criacaoDates.length > 0 ? this.criacaoDates[0].toString().slice(0, 10).split('-').reverse().join('/') : ''
      const date2 = this.criacaoDates.length > 1 ? this.criacaoDates[1].toString().slice(0, 10).split('-').reverse().join('/') : ''
      return this.criacaoDates.length == 0 ? ' ' : this.criacaoDates.length == 1 ? date1 : date1 + ' ~ '+ date2
    },
    envioDatesText () {
      const date1 = this.envioDates.length > 0 ? this.envioDates[0].toString().slice(0, 10).split('-').reverse().join('/') : ''
      const date2 = this.envioDates.length > 1 ? this.envioDates[1].toString().slice(0, 10).split('-').reverse().join('/') : ''
      return this.envioDates.length == 0 ? ' ' : this.envioDates.length == 1 ? date1 : date1 + ' ~ '+ date2
    },
  },
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    async fetchData(url, errorMessage) {
      try {
        const res = await csapi.get(url);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      }
    },

    async getData() {
      this.showLoader('Carregando Notificações...');

      this.datasetFiltered = this.dataset = []
      this.datasetUsuarios = []

      let query = '?'
      if (this.criacaoDates.length > 0) query += `&intervalocriacao=${this.criacaoDates[0]},${this.criacaoDates[this.criacaoDates.length - 1]}`
      if (this.envioDates.length > 0) query += `&intervalorenvio=${this.envioDates[0]},${this.envioDates[this.envioDates.length - 1]}`
      query += `&page=1&limit=999999` // Parametros exisntes para implementações futuras
      
      const link = '/v1/agronotificacao' + query; 
      let data = await this.fetchData(link, 'Erro ao carregar notificações');

      this.datasetFiltered = this.dataset = data.reverse();
      this.hideLoader();
      this.setFilters();
    },

    async getUsuarios(id) {
      this.datasetUsuariosGrouped.length = 0
      this.datasetUsuariosLoaded = false
      this.filtrarLidas = 'TODAS'
      // query += `&confirmadas=${this.filtrarLidas == 'LIDAS + CONFIRMADAS'}`// Parametros exisntes para implementações futuras
      const link = '/v1/agronotificacao/' + id + '/usuarios?&page=1&limit=999999'; // Parametros exisntes para implementações futuras
      let data = await this.fetchData(link, 'Erro ao carregar usuários');

      data.forEach(row => {
        row.hashcliente = row.cliente.hash
        row.nomecliente = row.cliente.nome
        row.nomeusuario = row.codigousuario + ' - ' + row.nomeusuario
        row.datahorarecebimento = row.datahorarecebimento ? new Date(row.datahorarecebimento).toLocaleString() : ''
        row.datahoraleitura = row.datahoraleitura ? new Date(row.datahoraleitura).toLocaleString() : ''
        row.datahoraconfirmacao = row.datahoraconfirmacao ? new Date(row.datahoraconfirmacao).toLocaleString() : ''
      })

      this.datasetUsuariosGrouped = this.agruparUsuariosEmpresas(data)
      this.datasetUsuariosGroupedNotFiltered = JSON.parse(JSON.stringify(this.datasetUsuariosGrouped))
      this.datasetUsuariosLoaded = true
    },  

    filtrarEmpresasUsuarios() {
      const dataLocal = JSON.parse(JSON.stringify(this.datasetUsuariosGroupedNotFiltered))
      this.datasetUsuariosGrouped.length = 0

      if (this.filtrarLidas == 'TODAS') {
        this.datasetUsuariosGrouped = dataLocal
      } else if (this.filtrarLidas == 'LIDAS') {
        this.datasetUsuariosGrouped = dataLocal.filter(item => item.lidas > 0)
        this.datasetUsuariosGrouped.forEach(row => row.items = row.items.filter(item => item.datahoraleitura != ''))
      } else if (this.filtrarLidas == 'LIDAS + CONFIRMADAS') {
        this.datasetUsuariosGrouped = dataLocal.filter(item => item.lidas > 0 && item.confirmadas > 0)
        this.datasetUsuariosGrouped.forEach(row => row.items = row.items.filter(item => item.datahoraleitura != '' && item.datahoraconfirmacao != ''))
      }
    },

    filtrarDataset() {
      if (this.filtrarMarca == 'Ambos') {
        this.datasetFiltered = this.dataset
      } else {
        this.datasetFiltered = this.dataset.filter(item => item.marca.toLowerCase() == this.filtrarMarca.toLowerCase())
      }
    },

    async deleteNotificacao() {

      try {
        this.showLoader('Excluindo Notificação...');
        await csapi.delete('/v1/agronotificacao/' + this.itemInteracao.id);
        this.$toast.success('Notificação excluída com sucesso!');
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao excluir notificação: ${error.message}`);
      } finally {
        this.datasetFiltered = this.dataset = []
        this.hideLoader()
        this.itemInteracao = {}
        this.dialogExcluir = false;
      }

      this.getData()
    },

    activeDialogClonar(item) {
      this.exibirDialogClonar = true
      this.itemInteracao = item
    },

    closeDialogClonar() {
      this.exibirDialogClonar = false
      this.itemInteracao = {}
    },

    async clonarMensagem() {
      if (!Number.isInteger(this.itemInteracao.id) || this.itemInteracao.id <= 0) {
        return;
      }

      this.showLoader('Clonando notificação...');
      try {
        const API_URL = `/v1/agronotificacao/${this.itemInteracao.id}`;
        const res = await csapi.get(API_URL);

        const envio = {
          titulo: res.data.titulo,
          marca: 'CONTROLSOFT',
          tipo: 'GERAL',
          nivelusuario: 'TODOS',
          exigeconfirmacao: false,
          bloqueiasistema: false,
          tipoconfirmacao: 'UM',
          mensagem: res.data.mensagem,
          listatipoempresa: [],
          listauf: [],
          listamodulo: [],
          listaclienteempresa: [],
        }

        const { login, name } = JSON.parse(JSON.stringify(this.$store.state.auth.user));
        envio.usuariocadastro = login + ':' + name
        const retorno = await csapi.post('/v1/agronotificacao', envio)

        this.$toast.success('Notificação clonada com sucesso!');   
        this.$router.push(`/agronotificacao/cadastro/${retorno.data.id}`);  
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao clonar notificação: ${error.message}`);
      } finally {
        this.hideLoader();
      }
    },

    async enviarNotificacao() {

      try {
        this.showLoader('Enviando Notificação...');
        const { login, name } = JSON.parse(JSON.stringify(this.$store.state.auth.user));
        const usurioenvio = { usuarioenvio: login + ':' + name }
        await csapi.post('/v1/agronotificacao/' + this.itemInteracao.id + '/enviar', usurioenvio);
        this.$toast.success('Notificação enviada com sucesso!');
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao enviar notificação: ${error.message}`);
      } finally {
        this.datasetFiltered = this.dataset = []
        this.hideLoader()
        this.itemInteracao = {}
        this.dialogEnviar = false;
      }

      this.getData()
    },

    agruparUsuariosEmpresas(usuarios) {
      let usuariosempresa = []
      usuarios.forEach(row => {
        const index = usuariosempresa.findIndex(u => u.idcliente == row.cliente.idweb)
        if(index == -1) usuariosempresa.push({ idcliente: row.cliente.idweb, nomecliente: row.cliente.nome, hashcliente: row.hashcliente, items: [row] })
        else usuariosempresa[index].items.push(row)
      })
      
      usuariosempresa.forEach(row => {
        row.quantidade = row.items.length
        row.recebidas = row.items.filter(u => u.datahorarecebimento != '').length
        row.lidas = row.items.filter(u => u.datahoraleitura != '').length
        row.confirmadas = row.items.filter(u => u.datahoraconfirmacao != '').length
      })	

      return usuariosempresa
    },

    setFilters() {
      this.filters.items.length = 0;

      try {
        this.filters.items.push(
          {
            text: 'Softhouse',
            type: 'select',
            values: [
              { field: 'marca', text: "ControlSoft", value: "CONTROLSOFT" },
              { field: 'marca', text: "SySafra", value: "SYSAFRA" }
            ],
          },
        );
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push(
          {
            text: 'Tipo',
            type: 'select',
            values: [
              { field: 'tipo', text: "Geral", value: "GERAL" },
              { field: 'tipo', text: "Administrativa", value: "ADMINISTRATIVA" },
              { field: 'tipo', text: "Sistema", value: "SISTEMA" },
            ],
          },
        );
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push(
          {
            text: 'Nível de Usuário',
            type: 'select',
            values: [
              { field: 'nivelusuario', text: "Todos", value: "TODOS" },
              // { field: 'nivelusuario', text: "Sistema", value: "SISTEMA" },
              { field: 'nivelusuario', text: "Supervisor", value: "SUPERVISOR" }, 
            ],
          },
        );
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push(
          {
            text: 'Confirmação',
            type: 'select',
            values: [
              { text: "Sem confirmação", value: "um", fn: ([row]) => {
                return !row.exigeconfirmacao
              }},
              { text: "Ao menos uma confirmação de usuário", value: "dois", fn: ([row]) => {
                return row.exigeconfirmacao && row.tipoconfirmacao == 'UM' && !row.bloqueiasistema
              }},
              { text: "Ao menos uma confirmação de usuário + bloqueio", value: "tres", fn: ([row]) => {
                return row.exigeconfirmacao && row.tipoconfirmacao == 'UM' && row.bloqueiasistema
              }},
              { text: "Confirmação de todos os usuários", value: "quatro", fn: ([row]) => {
                return row.exigeconfirmacao && row.tipoconfirmacao == 'TODOS' && !row.bloqueiasistema
              }},
              { text: "Confirmação de todos os usuários + bloqueio", value: "cinco", fn: ([row]) => {
                return row.exigeconfirmacao && row.tipoconfirmacao == 'TODOS' && row.bloqueiasistema
              }},
            ],
          },
        );
      } catch (error) {
        console.error(error);
      }

    },
  },

  watch: {
    criacaoDates: function (val) {
      if (val.length > 1) {
        if (new Date(val[0]) > new Date(val[1])) this.criacaoDates = [val[1], val[0]]
      }
    },
    envioDates: function (val) {
      if (val.length > 1) {
        if (new Date(val[0]) > new Date(val[1])) this.envioDates = [val[1], val[0]]
      }
    },
  },	

  mounted() {
    this.isControl(true)
    this.setTitle('Central de Notificação')

    const initDate = (new Date(Date.now() + (new Date()).getTimezoneOffset() * 60000 - (2629800000 + 86400000) )).toISOString().substr(0, 10)
    const endDate = (new Date(Date.now() + (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

    this.criacaoDates = [initDate, endDate]

    this.getData()
  },
}
</script>
